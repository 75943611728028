import React, { useState, cloneElement } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import flowRight from "lodash.flowright";

import withUserCheck from "../../../utils/withUserCheck";
import { translate } from "../../../i18n/I18nProvider";
import can from "../../../utils/can";
import createSubject from "../../../utils/createSubject";
import EditSubscription from "./EditSubscription";
import Optional from "../../../utils/optional";

const MoreActionsMenu = ({
  children,
  subscription = {},
  onSwapClick,
  clientId,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSwap = () => {
    handleClose();
    onSwapClick();
  };

  return (
    <div>
      {cloneElement(children, { onClick: handleClick })}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem
          onClick={onSwap}
          disabled={
            !can(
              "swap",
              createSubject("SimCard", { client_id: Number(clientId) })
            )
          }
        >
          {translate("TOOLTIP.SWAP_SIMCARD")}
        </MenuItem>
        <div>
          <EditSubscription subscription={subscription} closeMenu={handleClose}>
            <MenuItem
              onClick={handleClose}
              disabled={
                !can(
                  "update",
                  createSubject("Quote::Subscription", {
                    client_id: Number(clientId),
                  })
                )
              }
            >
              {translate("SUBSCRIPTIONS.EDIT")}
            </MenuItem>
          </EditSubscription>
        </div>
      </Menu>
    </div>
  );
};

const mapStatesToProps = ({ selectClient, iotProducts }) => {
  return {
    clientId: Optional(selectClient.selectedClient?.id).or(void 0),
  };
};

export default flowRight(
  withUserCheck,
  connect(mapStatesToProps)
)(MoreActionsMenu);
