import React from 'react';

import StatusCell from '../../../partials/statusCell/StatusCell';
import ActionButtons from './ActionButtons';
import Optional from '../../../utils/optional';
import pickOptionsForStatusCell from '../../../partials/statusCell/actions/pickOptionsForStatusCell';
import STATUS_BOX_OPTIONS from '../../../consts/statusBoxOptions';
import createTableFilterSelectOptions from '../../../utils/createTableFilterSelectOptions';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import formatDate, { formatDateForSchedule } from '../../../utils/formatDate';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import customTableColumnSort from '../../../utils/customTableColumnSort';

export default function tableColumns({ client, lang }) {
  const columns = [
    {
      title: 'TABLE.ACTIONS',
      render: rowData => (
        <ActionButtons subscription={rowData} clientId={client} />
      ),
      disableClick: true,
      width: '40px',
      align: 'center',
      sorting: false,
      filtering: false
    },
    {
      title: 'PRODUCT',
      render: rowData =>
        Optional(rowData.skuTranslations?.[lang]).or(
          rowData.skuTranslations?.default
        ),
      field: 'owner_name',
      disableClick: true
    },
    {
      title: 'NAME',
      render: rowData => rowData.name,
      field: 'name',
      disableClick: true
    },
    {
      title: 'ACCOUNT',
      field: 'account',
      customSort: (a, b) => customTableColumnSort(a, b, 'account')
    },
    {
      title: 'CLIENT',
      render: rowData =>
        Optional(rowData.client)
          .map(client => client.name)
          .or(''),
      hideForUserView: true,
      field: 'client.name',
      sorting: false,
      searchable: false
    },
    {
      title: 'TABLE.DESCRIPTION',
      field: 'description'
    },
    {
      title: 'STATUS',
      field: 'status',
      render: rowData => (
        <StatusCell
          showTooltip={true}
          customStyles={{
            field: { width: 'fit-content', padding: '5px 10px' }
          }}
          tooltipComponent={
            isDefined(rowData.activationDate) ||
            isDefined(rowData.expiryDate) ? (
              <div>
                {isDefined(rowData.activationDate) && (
                  <div>{`Activation date : ${formatDateForSchedule(
                    rowData.activationDate
                  )}`}</div>
                )}
                {isDefined(rowData.expiryDate) && (
                  <div>{`Expire date : ${formatDateForSchedule(
                    rowData.expiryDate
                  )}`}</div>
                )}
              </div>
            ) : (
              void 0
            )
          }
          {...pickOptionsForStatusCell(
            STATUS_BOX_OPTIONS.SUBSCRIPTIONS_STATUS_OPTIONS,
            rowData.status
          )}
        />
      ),
      disableClick: true,
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.SIMCARD_ACTIVE)
      },
      sorting: false
    },
    {
      title: 'CREATED_AT',
      render: rowData => formatDate(rowData.created_at),
      exportTransformer: rowData => formatDate(rowData.created_at),
      field: 'created_at',
      hidden: true
    },
    {
      title: 'UPDATED_AT',
      render: rowData => formatDate(rowData.updated_at),
      exportTransformer: rowData => formatDate(rowData.updated_at),
      field: 'updated_at',
      hidden: true
    }
  ];

  let mappedColumns = [...columns];

  if (isDefined(client)) {
    mappedColumns = columns.filter(column =>
      isUndefined(column.hideForUserView)
    );
  }

  if (can('manage', createSubject('SimCard', { client_id: Number(client) }))) {
    mappedColumns = [
      ...mappedColumns,
      {
        title: 'ID',
        field: 'id',
        hidden: true
      }
    ];
  }

  return mappedColumns.map((column, i) => {
    return { ...column, id: i };
  });
}
