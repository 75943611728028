import { JSONAPI_URLS } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import getFullTableData from '../../../utils/getFullTableData';
import { mapTags } from '../../../modals/addingSchedules/actions/getVacations';

const getVacations = () => {
  return async dispatch => {
    const vacations = await getFullTableData(JSONAPI_URLS.VACATIONS, {});
    const tags = mapTags(vacations);

    dispatch(reduxActions.setVacations(vacations, tags));
  };
};

export default getVacations;
