import { URLS } from '../../../consts/endpoints';
import { postData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { isUndefined } from '../../../utils/isDefined';
import { translate } from '../../../i18n/I18nProvider';

const cancelSubscription = async ({
  setIsLoading,
  clientId,
  externalReference,
  isMultiedit,
  selectedRows,
  name
}) => {
  setIsLoading(true);
  let summaryInfo = {};
  if (!isMultiedit) {
    try {
      const response = await postData({
        url: `${URLS.QUOTE_CLIENTS}/${clientId}/subscriptions/${externalReference}/cancel`,
        passErrors: true
      });

      if (response?.status === RESPONSE_CODES.SUCCESS) {
        summaryInfo = updateSummaryInfo({
          summaryInfo,
          name,
          newElement: {
            isError: false
          }
        });
      }
    } catch (e) {
      summaryInfo = updateSummaryInfo({
        summaryInfo,
        name,
        newElement: {
          text: translate('MESSAGE.CREATE_ERROR', {
            name
          }),
          isError: true
        }
      });
    }
    setIsLoading(false);
    return { requestCompleted: true, summaryInfo };
  } else {
    let summaryInfo = {};
    for (const row of selectedRows) {
      const { name: elName, externalReference: extRef } = row;
      try {
        const response = await postData({
          url: `${URLS.QUOTE_CLIENTS}/${clientId}/subscriptions/${extRef}/cancel`,
          passErrors: true
        });

        if (response?.status === RESPONSE_CODES.SUCCESS) {
          summaryInfo = updateSummaryInfo({
            summaryInfo,
            name: elName,
            newElement: {
              isError: false
            }
          });
        }
      } catch (e) {
        summaryInfo = updateSummaryInfo({
          summaryInfo,
          name: elName,
          newElement: {
            text: translate('MESSAGE.CREATE_ERROR', {
              name
            }),
            isError: true
          }
        });
      }
      setIsLoading(false);
      return { requestCompleted: true, summaryInfo };
    }
  }
};

export default cancelSubscription;

export const updateSummaryInfo = ({ summaryInfo, name, newElement }) => {
  if (isUndefined(summaryInfo[name])) {
    return {
      ...summaryInfo,
      [name]: [newElement]
    };
  } else {
    return {
      ...summaryInfo,
      [name]: [...summaryInfo[name], newElement]
    };
  }
};
