import React, { useMemo } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/en-US';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';

import { DATE_FORMAT_SHORT } from '../../../consts/initialValues';
import withSettings from '../../../utils/withSettings';
import { ThemeProvider, withStyles } from '@material-ui/styles';
import moment from 'moment';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import styles, { materialDatePickerTheme } from '../styles/dateRow';

const DateRow = ({ changeDate, setChangeDate, settings, lang, classes }) => {
  const materialDateTheme = useMemo(() => {
    return materialDatePickerTheme(settings);
  }, [settings]);

  if (locale && locale.options) {
    locale.options.weekStartsOn = 1;
  }
 
  const onDateChange = selectedDate => {
    const date = moment(selectedDate).format('x');
    setChangeDate(Number(date));
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
      <div className={classes.root}>
        <div>Subscription change date</div>
        <ThemeProvider theme={materialDateTheme}>
          <KeyboardDatePicker
            inputVariant="outlined"
            ampm={false}
            value={changeDate}
            onChange={onDateChange}
            format={DATE_FORMAT_SHORT[lang]}
            autoOk={true}
            minDate={moment().add(1, 'M')}
            views={['year', 'month']}
            openTo='month'
            disablePast={true}
          />
        </ThemeProvider>
      </div>
    </MuiPickersUtilsProvider>
  );
};

const mapStatesToProps = ({ auth }) => {
  return {
    lang: auth.lang
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withSettings,
  withStyles(styles)
)(DateRow);
