import { createTheme } from '@material-ui/core';
import { customPickerStyles } from '../../../modals/editSchedule/styles/datePickers';

const styles = theme => {
  return {
    root: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  };
};

export default styles;

export const materialDatePickerTheme = settings => {
  return createTheme({
    overrides: {
      MuiOutlinedInput: {
        root: {
          width: '180px',
          padding: '0',
          borderRadius: '0'
        },
        input: {
          padding: '10px'
        },
        adornedEnd: {
          paddingRight: '0'
        }
      },
      MuiPickersMonth: {
        month: {
          color: settings.colors.primary
        },
        monthSelected: {
          backgroundColor: settings.colors.aside_menu,
          color: settings.colors.white
        },
        monthDisabled: {
          color: '#CACACA'
        },
        current: {
          color: settings.colors.primary
        }
      },
      MuiPickersYear: {
        year: {
          color: settings.colors.primary
        },
        yearSelected: {
          backgroundColor: settings.colors.aside_menu,
          color: settings.colors.white
        },
        yearDisabled: {
          color: '#CACACA'
        },
        current: {
          color: settings.colors.primary
        }
      },
      ...customPickerStyles(settings)
    }
  });
};
