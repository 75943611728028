export default {
  root: {
    width: '100%',
    backgroundColor: '#FFFFFF'
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    borderTop: '1px solid #EBEDF2',
    width: '100%',
    padding: '10px',
    marginTop: '10px',
    '& button': {
      margin: '10px'
    }
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '60px'
  },
  qtyContainer: { display: 'flex', alignItems: 'center' },
  appendixLink: {
    margin: '15px 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '19px',
    fontSize: '13px'
  },
  info: {
    fontSize: '10px'
  }
};

export const CUSTOM_STYLES = {
  inputRow: {
    root: { width: '200px', alignItems: 'flex-start' },
    textfield: {
      width: '80px',
      fontSize: '15px',
      fontWeight: 600
    }
  },
  selectRow: {
    field: {
      width: '400px'
    }
  },
  acountSelect: {
    root: { width: '340px', marginLeft: 0 },
    label: { paddingLeft: 0, marginRight: '45px' },
    value: { width: '300px' }
  }
};
