import { withStyles } from '@material-ui/styles';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';

import styles, { dataRowCustomStyles } from '../styles/additionslData';
import DataRow from '../../../partials/Datarow/DataRow';
import { translate } from '../../../i18n/I18nProvider';
import { formatDate } from '../../externalNumberRange/actions/mapInactiveCellValue';
import PhoneBookInfo from './PhoneBookInfo';
import AdditionalDataMenu from './AdditionalDataMenu';
import clsx from 'clsx';
import { isDefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import STATUSES from '../../../consts/statusBoxOptions';
import DatalimitUsage from './DatalimitUsage';

const AdditionalData = ({
  classes,
  externalNoData = {},
  simcardData = {},
  datalimitData = {},
  number,
  isLandlineNumber,
  lookupData = {}
}) => {
  const dataRowCustomClasses = { value: classes.datarowValue };

  const network = useMemo(() => {
    return Optional(externalNoData?.provider?.sane_name).or(
      Optional(simcardData?.mobile_network?.brand).or('')
    );
  }, [externalNoData, simcardData]);
  return (
    <div className={classes.root}>
      <div className={classes.label}>
        <div>{translate('NUMBER.ADDITIONAL_DATA')}</div>
        <AdditionalDataMenu>
          <i className={clsx('fas fa-ellipsis-h', classes.dotsIcon)} />
        </AdditionalDataMenu>
      </div>
      <div className={classes.columnsContainer}>
        <DataRow
          label={translate('TABLE.NUMBER')}
          value={Optional(simcardData?.msisdn).or(number)}
          customClasses={dataRowCustomClasses}
          dense
        />
        <DataRow
          label={translate('TABLE.TYPE')}
          value={externalNoData?.number_type}
          customClasses={dataRowCustomClasses}
          customStyles={dataRowCustomStyles}
          dense
        />
        <DataRow
          label={`Dstny ${translate('TABLE.NETWORK')}`}
          value={network}
          customClasses={dataRowCustomClasses}
          dense
        />
        <DataRow
          label="OCH Service Operator"
          value={lookupData?.servoper_N}
          customClasses={dataRowCustomClasses}
          customStyles={dataRowCustomStyles}
          dense
        />
        <DataRow
          label={translate('TABLE.ACTIVE_AT')}
          value={formatDate(externalNoData?.och_active_at)}
          customClasses={dataRowCustomClasses}
          dense
        />
        <DataRow
          label={translate('TABLE.INACTVE_AT')}
          value={formatDate(externalNoData?.och_inactive_at)}
          customClasses={dataRowCustomClasses}
          customStyles={dataRowCustomStyles}
          dense
        />
        <DataRow
          label={translate('STATUS')}
          value={externalNoData?.status}
          customClasses={dataRowCustomClasses}
          dense
        />
        {!isLandlineNumber && (
          <>
            <DataRow
              label={translate('SIMCARD_NUMBER')}
              value={simcardData?.icc}
              customClasses={dataRowCustomClasses}
              customStyles={dataRowCustomStyles}
              dense
            />
            <DataRow
              label={`${translate('NUMBER.ACTIVE')} ${translate(
                'STATUS'
              ).toLowerCase()}`}
              value={
                isDefined(simcardData?.active)
                  ? simcardData?.active
                    ? translate('ACTIVE').toLowerCase()
                    : translate('INACTIVE').toLowerCase()
                  : ''
              }
              customClasses={dataRowCustomClasses}
              dense
            />
            <DataRow
              label={translate('CARD_TYPE')}
              value={Optional(
                STATUSES.SIMCARD_TYPE[simcardData?.card_type]?.intlKey
              )
                .map(key => translate(key))
                .or(simcardData?.card_type)}
              customClasses={dataRowCustomClasses}
              customStyles={dataRowCustomStyles}
              dense
            />
            <DatalimitUsage />
          </>
        )}
      </div>
      <PhoneBookInfo />
    </div>
  );
};

const mapStatesToProps = ({ checkNumber }) => {
  const {
    externalNoData,
    simcardData,
    number,
    lookupData,
    datalimitData
  } = checkNumber;
  return {
    externalNoData,
    simcardData,
    datalimitData,
    number,
    lookupData,
    isLandlineNumber: externalNoData?.number_type === 'landline' ? true : false
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(AdditionalData);
