import { createReduxActionTypes } from "../../../utils/actionFactory";

const SET_SELECT_OPTIONS = "SET_SELECT_OPTIONS";
const RESET = "RESET";
const SET_COLUMNS = "SET_COLUMNS";
const SET_SUBSCRIPTIONS = "SET_SUBSCRIPTIONS";
const TOGGLE_ACTIVATE_DIALOG = "TOGGLE_ACTIVATE_DIALOG";
const SET_ACCOUNTS = "SET_ACCOUNTS";
const SET_SELECTED_ACCOUNT = "SET_SELECTED_ACCOUNT";
const OPEN_SUMMARY = "OPEN_SUMMARY";
const CLOSE_SUMMARY = "CLOSE_SUMMARY";
const TOGGLE_ACTIVATION_SUMMARY = "TOGGLE_ACTIVATION_SUMMARY";

export const actionTypes = createReduxActionTypes("SUBSCRIPTIONS_IOT", [
  SET_SELECT_OPTIONS,
  SET_COLUMNS,
  SET_SUBSCRIPTIONS,
  RESET,
  TOGGLE_ACTIVATE_DIALOG,
  SET_ACCOUNTS,
  SET_SELECTED_ACCOUNT,
  OPEN_SUMMARY,
  CLOSE_SUMMARY,
  TOGGLE_ACTIVATION_SUMMARY,
]);

const DEFAULT_STATE = {
  selectsOptions: {},
  columns: [],
  subscriptions: [],
  activateDialogOpen: false,
  accounts: [],
  selectedAccount: {},
  summaryOpen: false,
  summaryData: {},
  activationSummaryOpen: false,
  orderId: void 0,
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_SUBSCRIPTIONS]: {
      return { ...state, subscriptions: action.subscriptions };
    }
    case actionTypes[SET_SELECT_OPTIONS]: {
      return { ...state, selectsOptions: { ...action.selectsOptions } };
    }
    case actionTypes[SET_COLUMNS]: {
      return { ...state, columns: [...action.columns] };
    }
    case actionTypes[SET_ACCOUNTS]: {
      return { ...state, accounts: [...action.accounts] };
    }
    case actionTypes[SET_SELECTED_ACCOUNT]: {
      return { ...state, selectedAccount: action.selectedAccount };
    }
    case actionTypes[TOGGLE_ACTIVATE_DIALOG]: {
      return { ...state, activateDialogOpen: action.activateDialogOpen };
    }
    case actionTypes[OPEN_SUMMARY]: {
      return {
        ...state,
        summaryData: action.summaryData,
        summaryOpen: true,
        fromChange: action.fromChange,
      };
    }
    case actionTypes[CLOSE_SUMMARY]: {
      return {
        ...state,
        summaryOpen: false,
        summaryData: DEFAULT_STATE.summaryData,
        fromChange: false,
      };
    }
    case actionTypes[TOGGLE_ACTIVATION_SUMMARY]: {
      const { activationSummaryOpen, orderId } = action;
      return {
        ...state,
        activationSummaryOpen,
        orderId,
      };
    }
    case actionTypes[RESET]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setSubscriptions: subscriptions => ({
    type: actionTypes[SET_SUBSCRIPTIONS],
    subscriptions,
  }),
  setSelectsOptions: selectsOptions => ({
    type: actionTypes[SET_SELECT_OPTIONS],
    selectsOptions,
  }),
  setColumns: columns => ({
    type: actionTypes[SET_COLUMNS],
    columns,
  }),
  setAccounts: accounts => ({
    type: actionTypes[SET_ACCOUNTS],
    accounts,
  }),
  setSelectedAccount: selectedAccount => ({
    type: actionTypes[SET_SELECTED_ACCOUNT],
    selectedAccount,
  }),
  toggleActivateDialog: activateDialogOpen => ({
    type: actionTypes[TOGGLE_ACTIVATE_DIALOG],
    activateDialogOpen,
  }),
  openSummary: (summaryData, fromChange) => ({
    type: actionTypes[OPEN_SUMMARY],
    summaryData,
    fromChange,
  }),
  toggleActivationSummary: (activationSummaryOpen, orderId) => ({
    type: actionTypes[TOGGLE_ACTIVATION_SUMMARY],
    activationSummaryOpen,
    orderId,
  }),
  closeSummary: () => ({
    type: actionTypes[CLOSE_SUMMARY],
  }),
  resetSimCards: () => ({ type: actionTypes[RESET] }),
};
