import { URLS } from '../../../consts/endpoints';
import { postData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { updateSummaryInfo } from './cancelSubscription';
import { translate } from '../../../i18n/I18nProvider';
import moment from 'moment';
import Optional from '../../../utils/optional';

const changeSubscription = async ({
  changeDate,
  clientId,
  externalReference,
  isMultiedit,
  selectedRows,
  setIsLoading,
  newProductValue,
  name
}) => {
  setIsLoading(true);
  const mappedDate = moment(changeDate).format('YYYY-MM');

  if (!isMultiedit) {
    let summaryInfo = {};
    try {
      const response = await postData({
        url: `${URLS.QUOTE_CLIENTS}/${clientId}/subscriptions/${externalReference}/change`,
        passErrors: true,
        data: {
          change_date: `${mappedDate}-01`,
          new_sku: newProductValue
        }
      });

      if (response?.status === RESPONSE_CODES.SUCCESS) {
        summaryInfo = updateSummaryInfo({
          summaryInfo,
          name,
          newElement: {
            isError: false
          }
        });
      }
    } catch (e) {
      summaryInfo = updateSummaryInfo({
        summaryInfo,
        name,
        newElement: {
          text: translate('MESSAGE.CREATE_ERROR', {
            name
          }),
          isError: true,
          errors: Optional(e.response?.data).or([])
        }
      });
    } finally {
      setIsLoading(false);
      return { requestCompleted: true, summaryInfo };
    }
  } else {
    let summaryInfo = {};
    for (const row of selectedRows) {
      const { name: elName, externalReference: extRef } = row;
      try {
        const response = await postData({
          url: `${URLS.QUOTE_CLIENTS}/${clientId}/subscriptions/${extRef}/change`,
          passErrors: true,
          data: {
            change_date: `${mappedDate}-01`,
            new_sku: newProductValue
          }
        });

        if (response?.status === RESPONSE_CODES.SUCCESS) {
          summaryInfo = updateSummaryInfo({
            summaryInfo,
            name: elName,
            newElement: {
              isError: false
            }
          });
        }
      } catch (e) {
        summaryInfo = updateSummaryInfo({
          summaryInfo,
          name: elName,
          newElement: {
            text: translate('MESSAGE.CREATE_ERROR', {
              name
            }),
            isError: true,
            errors: Optional(e.response?.data).or([])
          }
        });
      }
      setIsLoading(false);
      return { requestCompleted: true, summaryInfo };
    }
  }
};

export default changeSubscription;
