import { actions as reduxActions } from '../reducers';
import { getData } from '../../../utils/http';
import { JSONAPI_URLS } from '../../../consts/endpoints';
import { isArray, isUndefined } from '../../../utils/isDefined';
import { createVacationsSelectOptions } from '../../editSchedule/actions/getVacations';
import orderBy from 'lodash.orderby';
import { uniq } from 'lodash';

const getVacations = () => {
  return async dispatch => {
    let vacations = [];
    let tags = [];

    try {
      const { data } = await getData(`${JSONAPI_URLS.VACATIONS}`);

      if (isArray(data)) {
        tags = mapTags(data);
        vacations = data;
      }
    } catch {
    } finally {
      const sortedVacations = orderBy(vacations, 'next_occurrence');

      dispatch(
        reduxActions.setVacations(
          createVacationsSelectOptions(sortedVacations),
          tags
        )
      );
    }
  };
};

export default getVacations;

export const mapTags = data => {
  const mappedData = data
    .map(el => el.tags)
    .reduce((arr, el) => {
      if (isUndefined(el)) {
        return arr;
      }

      return [...arr, ...el];
    }, []);

  const uniqueEl = uniq(mappedData);

  return uniqueEl.map(el => {
    return {
      label: el,
      value: el
    };
  });
};
