const COLORS = {
  active: '#92BC68',
  passive: '#D8C85A',
  deactive: '#C95454',
  standard: '#aac2d1',
  datacard: '#16374f',
  sharingCard: '#516b82',
  info: '#D8C85A'
};

export default {
  ORDERS: {
    completed: {
      color: COLORS.active,
      text: 'Completed'
    },
    pending: {
      color: COLORS.passive,
      text: 'Pending'
    },
    running: {
      color: COLORS.passive,
      text: 'Running'
    },
    cancelled: {
      color: COLORS.deactive,
      text: 'Cancelled'
    },
    failed: { color: COLORS.deactive, text: 'Failed' },
    error: {
      color: COLORS.deactive,
      text: 'Error'
    }
  },
  DATA_LIMITS: {
    active: {
      color: COLORS.active,
      text: 'Active',
      intlKey: 'DATA_LIMITS.ACTIVE'
    },
    recharged: {
      color: COLORS.active,
      text: 'Recharged',
      intlKey: 'DATA_LIMITS.RECHARGED'
    },
    rlh_billed: {
      color: COLORS.active,
      text: 'RLH Billed',
      intlKey: 'DATA_LIMITS.BILLED'
    },
    keep_open: {
      color: COLORS.passive,
      text: 'Keep Open',
      intlKey: 'DATA_LIMITS.KEEP_OPEN'
    },
    shut: { color: COLORS.deactive, text: 'Shut', intlKey: 'DATA_LIMITS.SHUT' },
    blocked: {
      color: COLORS.deactive,
      text: 'Blocked',
      intlKey: 'DATA_LIMITS.KEEP_BLOCKED'
    }
  },
  TRUE_FALSE: {
    true: { color: COLORS.active, intlKey: 'YES' },
    false: { color: COLORS.deactive, intlKey: 'NO' }
  },
  SIMCARD_TYPE: {
    data_card: { color: COLORS.datacard, intlKey: 'DATACARD' },
    standard: { color: COLORS.standard, intlKey: 'STANDARD_CARD' },
    data_sharing_card: {
      color: COLORS.sharingCard,
      intlKey: 'DATASHARING_CARD'
    }
  },
  SIMCARD_ACTIVE: {
    true: { color: COLORS.active, intlKey: 'TABLE.ACTIVE' },
    false: { color: COLORS.deactive, intlKey: 'TABLE.DEACTIVE' }
  },
  ENABLED: {
    true: { color: COLORS.active, intlKey: 'ENABLED' },
    false: { color: COLORS.deactive, intlKey: 'DISABLED' }
  },
  SCHEDULES: {
    OPEN: { color: COLORS.active, intlKey: 'OPEN' },
    CLOSED: { color: COLORS.deactive, intlKey: 'CLOSED' }
  },
  LOCK: {
    true: { color: COLORS.active, intlKey: 'LOCKED' },
    false: { color: COLORS.deactive, intlKey: 'UNLOCKED' }
  },
  PHONE_TYPES: {
    'Desk phone': { intlKey: 'DESK_PHONE' },
    'Analog phone': { intlKey: 'ANALOG_PHONE' }
  },
  NUMBER_TYPES: {
    mobile: { intlKey: 'NUMBER_RANGES.MOBILE' },
    landline: { intlKey: 'NUMBER_RANGES.LANDLINE' }
  },
  NUMBER_STATUSES: {
    active: { intlKey: 'ACTIVE' },
    inactive: { intlKey: 'INACTIVE' },
    cancel: { intlKey: 'TERMINATED' },
    outported: { intlKey: 'MENU.OUTPORTED' }
  },
  PHONEBOOK_STATUSES: {
    N: { intlKey: 'PHONE_BOOK.NOT_SECRET' },
    U: { intlKey: 'PHONE_BOOK.HIDDEN' },
    H: { intlKey: 'PHONE_BOOK.SECTER_NUMBER' },
    A: { intlKey: 'PHONE_BOOK.SECRET_ADDRESS' }
  },
  SUBSCRIPTIONS_STATUS_OPTIONS: {
    INITIAL: { color: COLORS.info, text: 'Initial' },
    ACTIVATION_FAILED: { color: COLORS.deactive, text: 'Activation failed' },
    IN_ACTIVATION_PROCESS: {
      color: COLORS.info,
      text: 'In activation process'
    },
    TERMINATION_PENDING: {
      color: COLORS.deactive,
      text: 'Termination pending'
    },
    IN_TERMINATION_PROCESS: {
      color: COLORS.deactive,
      text: 'In termination process'
    },
    TERMINATED: { color: COLORS.deactive, text: 'Terminated' },
    TERMINATION_FAILED: { color: COLORS.deactive, text: 'Termination failed' },
    ACTIVE: { color: COLORS.active, text: 'Active' },
    CREATE_CANCELLED: { color: COLORS.deactive, text: 'Create cancelled' },
    OUTPORTING: { color: COLORS.deactive, text: 'Outporting' },
    OUTPORTED: { color: COLORS.deactive, text: 'Outported' }
  }
};

export const WEEKDAYS = {
  1: 'MONDAY',
  2: 'TUESDAY',
  3: 'WEDNESDAY',
  4: 'THURSDAY',
  5: 'FRIDAY',
  6: 'SATURDAY',
  7: 'SUNDAY'
};
